<template>
  <v-container id="user-tables" fluid tag="section">
    <base-material-card icon="mdi-clipboard-text" class="px-5 py-3">
      <v-tabs color="#1b5e20">
        <v-tab>Table</v-tab>
        <v-tab>Divide</v-tab>
        <v-tab-item>
          <v-card-title>
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="#1b5e20"
                  class="white--text"
                  elevation="2"
                  v-bind="attrs"
                  v-on="on"
                  >Add Cashflow
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6" md="12">
                          <v-text-field
                            v-model="editedItem.name"
                            label="Cashflow Name"
                            required
                            :rules="nameRules"
                            :disabled="isDisabled"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="12">
                          <v-text-field
                            v-model="editedItem.debit"
                            label="Debit Price"
                            type="number"
                            prefix="Rp"
                            :disabled="isDisabled"
                            @input="countSaldo"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="12">
                          <v-text-field
                            v-model="editedItem.credit"
                            label="Credit Price"
                            type="number"
                            prefix="Rp"
                            :disabled="isDisabled"
                            @input="countSaldo"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="12">
                          <v-text-field
                            v-model="editedItem.saldo"
                            label="Saldo Price"
                            :disabled="isDisabled"
                            required
                            type="number"
                            prefix="Rp"
                            ref="saldo"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="12">
                          <v-text-field
                            v-model="editedItem.total_infaq"
                            label="Total Infaq"
                            type="number"
                            prefix="Rp"
                            ref="total_infaq"
                            :disabled="!isDisabled"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-form>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="#1b5e20" class="white--text" elevation="2" @click="close">
                    Cancel
                  </v-btn>
                  <v-btn color="#1b5e20" class="white--text" elevation="2" @click="save">
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <div class="warning text-center">
                  <header class="text-h5">Confirmation</header>
                </div>
                <v-card-title class="text-h6"
                  >Are you sure you want to delete this {{ editedItem.name }}
                  ?
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="#1b5e20"
                    class="white--text"
                    elevation="2"
                    @click="closeDelete"
                    >Cancel</v-btn
                  >
                  <v-btn
                    color="#1b5e20"
                    class="white--text"
                    elevation="2"
                    @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-spacer></v-spacer>
            <v-text-field
              v-model="keyword"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              @input="doSearch"
            >
            </v-text-field>
          </v-card-title>
          <v-data-table
            ref="vDataTable"
            :headers="headers"
            :items="cashflows"
            hide-default-footer
            class="elevation-0"
          >
            <template #item="{ item, index, headers }">
              <tr>
                <td>
                  {{ index + 1 + (page - 1) * 10 }}
                </td>
                <td>
                  {{ item.name }}
                </td>
                <td>
                  {{ item.debit }}
                </td>
                <td>
                  {{ item.credit }}
                </td>
                <td>
                  {{ item.saldo }}
                </td>
                <td>
                  {{ item.created_at }}
                </td>
                <td>
                  {{ item.savings }}
                </td>
                <td>
                  <div
                    v-if="item.remark_dividen != null"
                    style="background-color: orange"
                  >
                    {{ item.remark_dividen }}
                  </div>
                </td>
                <td>
                  {{ item.total_infaq }}
                </td>
                <td>
                  <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
                  <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
          <div class="text-center">
            <v-pagination
              v-model="page"
              @input="go"
              :length="lengthPage"
              :total-visible="5"
              color="#1b5e20"
            >
            </v-pagination>
          </div>
        </v-tab-item>

        <!-- ============================================== -->
        <v-tab-item>
          <v-card-title>
            <v-dialog v-model="dialogDivideProfits" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="#1b5e20"
                  class="white--text"
                  elevation="2"
                  v-bind="attrs"
                  v-on="on"
                  >Add Divide Profits
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ "Divide Profits" }}</span>
                </v-card-title>
                <v-form ref="formDivideProfits" v-model="valid" lazy-validation>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6" md="12">
                          <v-text-field
                            v-model="editedItem.remark"
                            label="Remark"
                            required
                            :rules="remarkRules"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="12">
                          <v-text-field
                            v-model="editedItem.divide"
                            label="divide Price"
                            type="number"
                            prefix="Rp"
                            required
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-form>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="#1b5e20"
                    class="white--text"
                    elevation="2"
                    @click="closeDivideProfits"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="#1b5e20"
                    class="white--text"
                    elevation="2"
                    @click="saveDivideProfits"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-spacer></v-spacer>
            <v-text-field
              v-model="keyword"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              @input="doSearchDivideProfits"
            >
            </v-text-field>
          </v-card-title>
          <v-data-table
            ref="vDataTable"
            :headers="headersDivideProfits"
            :items="divideProfits"
            hide-default-footer
            class="elevation-0"
          >
            <template #item="{ item, index, headers }">
              <tr>
                <td>
                  {{ index + 1 + (page - 1) * 10 }}
                </td>
                <td>
                  {{ item.divide }}
                </td>
                <td>
                  {{ item.remark }}
                </td>
                <td>
                  {{ item.created_at }}
                </td>
              </tr>
            </template>
          </v-data-table>
          <div class="text-center">
            <v-pagination
              v-model="pageDevide"
              @input="goDivideProfits"
              :length="lengthPageDevide"
              :total-visible="5"
              color="#1b5e20"
            >
            </v-pagination>
          </div>
        </v-tab-item>
      </v-tabs>
    </base-material-card>
    <div class="text-center">
      <v-dialog v-model="progressBar" width="100" hide-overlay>
        <v-progress-circular
          :size="60"
          color="primary"
          indeterminate
          align-center
          justify-center
        >
        </v-progress-circular>
      </v-dialog>
    </div>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data: () => ({
    valid: true,
    isDisabled: false,
    cashflows: [],
    divideProfits: [],
    keyword: "",
    page: 0,
    lengthPage: 0,
    pageDevide: 0,
    lengthPageDevide: 0,
    headers: [
      { text: "No", value: "id" },
      { text: "Name Cashflow", value: "name" },
      { text: "Debit", value: "debit" },
      { text: "Credit", value: "credit" },
      { text: "Saldo", value: "saldo" },
      { text: "Created Date", value: "created_at" },
      { text: "Savings saldo", value: "savings" },
      { text: "Dividen", value: "remark_dividen" },
      { text: "Infaq Total", value: "total_infaq" },
      { text: "Actions", sortable: false },
    ],
    headersDivideProfits: [
      { text: "No", value: "id" },
      { text: "Divide", value: "divide" },
      { text: "Remark", value: "remark" },
      { text: "Created Date", value: "created_at" },
    ],
    dialog: false,
    dialogDivideProfits: false,
    dialogDelete: false,
    editedIndex: -1,
    editedItem: {
      name: "",
      debit: "",
      credit: "",
      saldo: "",
      created_at: "",
      updated_at: "",
      divide: "",
      remark: "",
    },
    defaultItem: {
      name: "",
      debit: "",
      credit: "",
      saldo: "",
      created_at: "",
      updated_at: "",
      divide: "",
      remark: "",
    },
    selectedFile: null,
    progressBar: false,
    nameRules: [
      (v) => !!v || "Cashflow Name is required",
      (v) => (v && v.length <= 100) || "Max 100 characters",
    ],
    remarkRules: [
      (v) => !!v || "Remark is required",
      (v) => (v && v.length <= 100) || "Max 100 characters",
    ],
  }),
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "New Cashflow" : "Edit Cashflow";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.go();
    this.goDivideProfits();
  },
  methods: {
    ...mapActions({
      setAlert: "alert/set",
      setAuth: "auth/set",
    }),
    go() {
      this.progressBar = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      let url = "/cashflow?page=" + this.page;
      this.axios
        .get(url, config)
        .then((response) => {
          let { data } = response.data;
          let { meta } = response.data;
          this.cashflows = data;
          // jumlah halaman di dapat dari meta endpoint cashflows
          this.lengthPage = meta.last_page;
          this.page = meta.current_page;
          this.progressBar = false;
        })
        .catch((error) => {
          let { responses } = error;
          console.log(responses);
        });
    },
    goDivideProfits() {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      let url = "/divideProfits?page=" + this.page;
      this.axios
        .get(url, config)
        .then((response) => {
          let { data } = response.data;
          let { meta } = response.data;
          this.divideProfits = data;
          // jumlah halaman di dapat dari meta endpoint cashflows
          this.lengthPageDevide = meta.last_page;
          this.pageDevide = meta.current_page;
        })
        .catch((error) => {
          let { responses } = error;
          console.log(responses);
        });
    },
    getColor(status) {
      if (status == "PUBLISH") return "green";
      else return "red";
    },
    doSearch() {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      let keyword = this.keyword;
      if (keyword.length > 0) {
        this.axios
          .get("/cashflow/search/" + keyword, config)
          .then((response) => {
            let { data } = response.data;
            this.cashflows = data;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.go();
      }
    },
    doSearchDivideProfits() {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      let keyword = this.keyword;
      if (keyword.length > 0) {
        this.axios
          .get("/divideProfits/search/" + keyword, config)
          .then((response) => {
            let { data } = response.data;
            this.divideProfits = data;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.go();
      }
    },
    editItem(item) {
      this.editedIndex = this.cashflows.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      this.isDisabled = true;
    },
    close() {
      this.dialog = false;
      this.isDisabled = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDivideProfits() {
      this.dialogDivideProfits = false;
      this.isDisabled = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteItem(item) {
      this.editedIndex = this.cashflows.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.progressBar = true;
      let formData = new FormData();
      formData.set("id", this.editedItem.id);
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      this.axios
        .post("/cashflow/delete-permanent", formData, config)
        .then((response) => {
          let { data } = response;
          this.setAuth(data.data);
          this.setAlert({
            status: true,
            text: data.message,
            color: "success",
          });
          this.cashflows.splice(this.editedIndex, 1);
          this.closeDelete();
          this.cashflows.push(this.editedItem);
          this.clear();
        })
        .catch((error) => {
          let { data } = error;
          this.setAlert({
            status: true,
            text: data.message,
            color: "error",
          });
          this.cashflows.push(this.editedItem);
          this.clear();
        });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.$refs.form.validate()) {
        this.progressBar = true;
        let formData = new FormData();
        formData.set("name", this.editedItem.name);
        formData.set("user_id", this.user.id);
        formData.set("id", this.editedItem.id);
        formData.set("debit", this.editedItem.debit);
        formData.set("credit", this.editedItem.credit);
        formData.set("saldo", this.editedItem.saldo);
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.api_token,
          },
        };
        if (this.editedIndex > -1) {
          formData.set("total_infaq", this.editedItem.total_infaq);
          Object.assign(this.cashflows[this.editedIndex], this.editedItem);
          this.axios
            .post("/cashflow/update", formData, config)
            .then((response) => {
              let { data } = response;
              this.setAuth(data.data);
              this.setAlert({
                status: true,
                text: data.message,
                color: data.status,
              });
              this.cashflows.push(this.editedItem);
              this.clear();
            })
            .catch((error) => {
              let { data } = error;
              this.setAlert({
                status: true,
                text: data.message,
                color: "error",
              });
              this.clear();
            });
        } else {
          this.axios
            .post("/cashflow/store", formData, config)
            .then((response) => {
              let { data } = response;
              this.setAuth(data.data);
              this.setAlert({
                status: true,
                text: data.message,
                color: data.status,
              });
              this.cashflows.push(this.editedItem);
              this.clear();
            })
            .catch((error) => {
              let { data } = error;
              this.setAlert({
                status: true,
                text: data.message,
                color: "error",
              });
              this.clear();
            });
        }
      }
    },
    saveDivideProfits() {
      if (this.$refs.formDivideProfits.validate()) {
        this.progressBar = true;
        let formData = new FormData();
        formData.set("divide", this.editedItem.divide);
        formData.set("user_id", this.user.id);
        formData.set("remark", this.editedItem.remark);
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.api_token,
          },
        };
        this.axios
          .post("/divideProfits/store", formData, config)
          .then((response) => {
            let { data } = response;
            this.setAuth(data.data);
            this.setAlert({
              status: true,
              text: data.message,
              color: data.status,
            });
            this.divideProfits.push(this.editedItem);
            this.clear();
          })
          .catch((error) => {
            let { data } = error;
            this.setAlert({
              status: true,
              text: data.message,
              color: "error",
            });
            this.clear();
          });
      }
    },
    clear() {
      this.go();
      this.goDivideProfits();
      this.graph();
      this.close();
      this.closeDivideProfits();
      this.progressBar = false;
    },
    countSaldo() {
      this.editedItem.saldo = 0;
      let debit =
        this.editedItem.debit != null
          ? parseInt(this.editedItem.debit != "" ? this.editedItem.debit : 0)
          : parseInt(0);
      let credit =
        this.editedItem.credit != null
          ? parseInt(this.editedItem.credit != "" ? this.editedItem.credit : 0)
          : parseInt(0);
      this.editedItem.saldo += credit - debit;
      this.$refs.saldo.update();
    },
  },
};
</script>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}

.position-relative {
  position: relative;
}
</style>
